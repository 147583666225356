<template>
    <modal ref="modalVerVideo" titulo="Adjunto" tamano="modal-xl">
        <div v-if="adjunto" class="image-viewer text-center">
            <video alt="image-viewer" style="max-width: 100%; max-height: calc(100vh - 300px);" autoplay controls>
                <source :src="adjunto" />
            </video>
        </div>
        <div v-else class="row mx-0">
            <div class="col-12 text-center">
                <p>Cargando video...</p>
            </div>
        </div>
    </modal>
</template>
<script>
export default {
    data(){
        return {
            adjunto: null,
        }
    },

    methods:{
        async toggle(adjunto){
            if(this.adjunto != adjunto) this.adjunto = null;
            
            setTimeout(() => {
                this.adjunto = adjunto;
                this.$refs.modalVerVideo.toggle();
            },200);
        },
    }
}
</script>
<style lang="scss" scoped>
.type-image-modal{
	object-fit: cover;
	max-width: 100%;
	max-height: 400px;
}
</style>